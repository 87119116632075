<template>
	<div class="BillList">
		
		<h3>
			<span>
				<em>
				<el-select placeholder="全部账期">
					<el-option>12月</el-option>
				</el-select>
				</em>
				<em>
					<el-select placeholder="全部状态">
						<el-option>已对账</el-option>
						<el-option>未对账</el-option>
					</el-select>
				</em>
				

				<el-button icon="el-icon-search">查询</el-button>
	
			</span>
			
			<div class="Export">
				<el-button type="primary" icon="el-icon-download">导出</el-button>
			</div>
			
		</h3>

		<div class="List">
			
				
			<div class="Title">
				<span class="Check">
					<el-checkbox></el-checkbox>
					全选
				</span>
				<span class="Month">账期</span>
				<span class="CreatedAt">出账时间</span>
				<span class="FinishAt">还款时间</span>
				<span class="Detail">账期信息</span>
				<span class="Status">状态</span>
				<span class="Action">操作</span>
			</div>
			
			<div class="One" v-for="item in 10" :key="item">
				<span class="Check"><el-checkbox></el-checkbox></span>
				<span class="Month">12月</span>
				<span class="CreatedAt">2019/09/10 14:35</span>
				<span class="FinishAt">-</span>
				<span class="Detail">
					<em @click="$Jump('/my/bill/bill_details')">查看明细</em>
				</span>
				<span class="Status">待还款</span>
				<span class="Action">去还款</span>
			</div>
				
		</div>
		
		<div style="margin-top: 20px;">
			<el-button type="primary">批量对账</el-button>
			<el-button type="primary">批量还款</el-button>
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="1000">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Checkbox,Pagination} from 'element-ui'
	export default {
	  name: 'BillDetails',
	  props: {
	  },
	  data() {
	      return {
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
.BillList{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 30px;
}
.BillList h3{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 20px;
}
.BillList h3 i{
	width: 100px;
	font-style: normal;
}
.BillList h3 span{
	flex: 1;
	display: flex;
}
.BillList h3 span em{
	margin-right: 5px;
}
.BillList  h3 .Export{
	
}

.List{
	border: 1px solid rgba(0,0,0,0.1);
}
.List .Title,.List .One{
	display: flex;
}
.List .Title{
	border-bottom: 1px solid rgba(0,0,0,0.1);
	line-height: 45px;
	color: rgba(0,0,0,0.4);
}
.List .One{
	padding: 20px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.List .Check{
	width: 80px;
	margin-left: 20px;
}
.List .Month{
	width: 100px;
}
.List .CreatedAt{
	width: 160px;
}
.List .FinishAt{
	width: 160px;
}
.List .Detail{
	flex: 1;
}
.List .Status{
	width: 100px;
}
.List .Action{
	width: 100px;
	text-align: right;
	margin-right: 20px;
}

.List .One .Action{
	color: rgba(228,0,0,1);
	cursor: pointer;
}
.List .One .Detail{
	color: rgba(40,151,255,1);
	cursor: pointer;
}
</style>
